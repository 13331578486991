export default {
  methods: {
    /**
     * Get the formatted document of a delivery
     * @param {License} delivery - the delivery
     */
    getFormattedDocumentTypeOfDelivery: function (delivery) {
      return this.getFormattedDeliveryDocumentType(delivery.documentType)
    },
    /**
     * Format an delivery document type
     * @param {String} documentType - the document type
     */
    getFormattedDeliveryDocumentType: function (documentType) {
      switch (documentType) {
        case 'starter': return 'Startdokument(e)'
        case 'license': return 'Lizenz(en)'
        default: return 'UNKNOWN: ' + documentType
      }
    },
    /**
     * Get the formatted occasion of a delivery
     * @param {License} delivery - the delivery
     */
    getFormattedOccasionOfDelivery: function (delivery) {
      return this.getFormattedDeliveryOccasion(delivery.occasion)
    },
    /**
     * Format an delivery occasion
     * @param {String} occasion - the occasion
     */
    getFormattedDeliveryOccasion: function (occasion) {
      switch (occasion) {
        case 'order': return 'Shop-Generierung'
        case 'manual': return 'Manuell'
        case 'bind': return 'Lizenzbindung'
        case 'update': return 'Update'
        case 'recovery': return 'Wiederherstellung'
        default: return 'UNKNOWN: ' + occasion
      }
    },

    getTagTypeForDeliveryOccasion: function (delivery) {
      switch (delivery?.occasion) {
        case 'order': return 'is-dark'
        case 'manual': return 'is-info'
        case 'bind': return 'is-primary'
        case 'update': return 'is-light'
        case 'recovery': return 'is-warning'
        default: return ''
      }
    }
  }
}
