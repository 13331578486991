/**
 * Download a blob without changing the current page
 * @param {Blog} blob - the blob
 * @param {String} filename - file name of the download
 */
export function downloadBlob (blob, filename) {
  const url = window.URL.createObjectURL(blob)

  // Create a element
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display:none'
  a.href = url
  a.download = filename

  a.click() // Perform download
  window.URL.revokeObjectURL(url) // Cleanup
  document.body.removeChild(a) // Remove a
}
/**
 * Capture and download the blob contained in the response
 * @param {Object} response - axios response
 * @param {String} type - mime type
 * @param {String} filename - filename
 */
export function downloadResponseBlob (response, type, filename) {
  // Capture blob and open it
  const blob = new Blob([response.data], { type })
  const date = new Date().toISOString().slice(0, 10)
  downloadBlob(blob, `${date} ${filename}`)
}

export default {
  downloadBlob,
  downloadResponseBlob
}
