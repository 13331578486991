<template lang="pug">
  #overview
    section.section
      .container.page
        .columns
          .column.is-2
          .column.is-8
            b-message(title="METACOM 9 Desktop ist verfügbar!" type="is-primary" v-if="license.version === '8'")
              .content
                p METACOM 9 Desktop zum Erstellen von Materialien zur Unterstützten Kommunikation wurde im Dezember 2022 veröffentlicht und besteht aus der METACOM 9 Symbolsammlung mit ca. 170000 Symbolen und MetaSearch mit vielen neuen Funktionen.

                .buttons
                  b-button(tag="a" href="https://metacom.shop/metacom-kaufen" target="_blank" type="is-primary") Jetzt (kostenpflichtig) updaten oder Angebot erstellen
                  b-button(tag="a" href="https://metacom.shop" target="_blank") Mehr erfahren
            h1.title Ihre Lizenz
            license-box(:license="license")
          .column.is-2

        .columns.is-centered(v-if="license.status === 'unbound'")
          .column.is-8
            b-message(closeable="false", type="is-primary")
              | Diese Lizenz ist noch nicht an einen Lizenznehmer gebunden.
              br
              | Um Sie zu aktivieren und auf den vollen Funktionsumfang zurückzugreifen, binden Sie sie nun bitte an einen Lizenznehmer.

            license-binding(
              :license="license"
              @bound="onLicenseBound()"
            )
    license-actions(
      v-if="license.status === 'bound'"
      :is-freshly-bound="didBindLicense"
      :license="license"
    )
</template>

<script>
import LicenseBox from '@/components/general/LicenseBox'
import LicenseBinding from '@/components/customer/LicenseBinding'
import LicenseActions from '@/components/customer/LicenseActions'

import license from '@/mixins/license'

export default {
  name: 'LicenseOverview',
  components: {
    LicenseBox,
    LicenseBinding,
    LicenseActions
  },
  mixins: [license],
  data () {
    return {
      license: this.$store.getters['auth/userData'],
      didBindLicense: false
    }
  },
  methods: {
    onLicenseBound: function () {
      this.didBindLicense = true
      this.reloadUser()
    },
    /**
     * Reloads the license user
     * Used to refresh the user after license binding
     */
    reloadUser: async function () {
      try {
        await this.$store.dispatch('auth/fetchUser')
        this.license = this.$store.getters['auth/userData']
      } catch (err) {
        console.error('Error reloading user: ' + err)
        this.$buefy.toast.open({
          type: 'is-danger',
          message: 'Ein Fehler ist aufgetreten, bitte Seite neu laden.'
        })
      }
    }

  }
}
</script>
