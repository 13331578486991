<template lang="pug">
  div#app
    router-view
    cookie-banner

</template>

<script>
import CookieBanner from '@/components/general/CookieBanner'
export default {
  name: 'App',
  components: {
    CookieBanner
  },
  created: function () {
    // Add interceptor for unauthorized redirect
    this.axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('auth/logout')
        }
        throw err
      })
    })
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/bulma-variables.scss';
  .section {
    &.section-dark {
      background: $dark;
      color: $white;
      .title {
        color: $white;
      }
    }
    &.section-white {
      background: $white;
      color: $text;
    }
    &.section-light {
      background: $light;
      color: $text;
    }
  }

  // Panel
  .panel-heading {
    font-size: 1.2em;
    font-weight: 700;
  }

  .tag.is-fullwidth {
    width: 100%;
  }

  .Cookie--cm-theme {
    background-color: $dark;
    .cookie-fullwidth {
      color: $light;
      margin: 0 auto;
      padding: 1em;
    }
  }
</style>
