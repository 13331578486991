export default {
  methods: {
    /**
     * Get the license status as pretty string
     * @param {String} licenseStatus - license status value
     * @returns {String} - formatted license status
     */
    getFormattedLicenseStatus: function (licenseStatus) {
      const licenseStatuses = { unbound: 'Ungebunden', bound: 'Gebunden', disabled: 'Deaktiviert' }
      return licenseStatuses[licenseStatus]
    },

    /**
     * Get the license class as pretty string
     * @param {String} licenseClass - license class value
     * @returns {String} - formatted license class
     */
    getFormattedLicenseClass: function (licenseClass) {
      const licenseClasses = {
        single: 'Einzellizenz',
        multiple: 'Mehrfachlizenz',
        server: 'Serverlizenz',
        pro: 'Pro-Lizenz',
        personal: 'Persönliche Lizenz'
      }
      return licenseClasses[licenseClass]
    },

    /**
     * Get the license type as pretty string
     * @param {String} licenseType - license type value
     * @returns {String} - formatted license type
     */
    getFormattedLicenseType: function (licenseType) {
      const licenseTypes = { full: 'Vollversion', update: 'Update' }
      return licenseTypes[licenseType]
    },

    /**
     * Get the license delivery type as pretty string
     * @param {String} licenseDelivery - license delivery value
     * @returns {String} - formatted license delivery type
     */
    getFormattedLicenseDelivery: function (licenseDelivery) {
      const deliveryType = { download: 'Download', dvd: 'DVD' }
      return deliveryType[licenseDelivery]
    },

    /**
     * Get the license image
     * @param {String} licenseClass - license class value
     * @param {String} licenseType - license type value
     */
    getLicenseImageForClassAndType: function (licenseClass, licenseType) {
      switch (licenseClass) {
        case 'server':
          if (licenseType === 'update') {
            return require('./../assets/images/license-images/serverliz_upd.svg')
          } else {
            return require('./../assets/images/license-images/serverliz_voll.svg')
          }
        case 'multiple':
          if (licenseType === 'update') {
            return require('./../assets/images/license-images/mehrfachliz_upd.svg')
          } else {
            return require('./../assets/images/license-images/mehrfachliz_voll.svg')
          }
        case 'single':
          if (licenseType === 'update') {
            return require('./../assets/images/license-images/einzellizenz_upd.svg')
          } else {
            return require('./../assets/images/license-images/einzellizenz_voll.svg')
          }

        case 'personal':
          if (licenseType === 'update') {
            return require('./../assets/images/license-images/m9_pers_upd.svg')
          } else {
            return require('./../assets/images/license-images/m9_pers_voll.svg')
          }

        case 'pro':
          if (licenseType === 'update') {
            return require('./../assets/images/license-images/m9_pro_upd.svg')
          } else {
            return require('./../assets/images/license-images/m9_pro_voll.svg')
          }
      }
    },

    /**
     * Get the Product image of a metacom version
     * e.g. METACOM 8
     * @param {String} version - License version
     */
    getLicenseProductImageForVersion: function (version) {
      switch (version) {
        case '8':
          return require('./../assets/images/license-images/einzellizenz_voll.svg')
        case '9':
          return require('./../assets/images/assistant/M9_pro_personal.png')
        default:
          return undefined
      }
    },

    /**
     * Get the formatted status of a license
     * @param {License} - license to format
     */
    getFormattedStatusOfLicense: function (license) {
      return this.getFormattedLicenseStatus(license.status)
    },

    /**
     * Get the formatted class of a license
     * @param {License} license - license to format
     */
    getFormattedClassOfLicense (license) {
      return this.getFormattedLicenseClass(license.properties.class)
    },

    /**
     * Get the formatted type of a license
     * @param {License} license - license to format
     */
    getFormattedTypeOfLicense (license) {
      return this.getFormattedLicenseType(license.properties.type)
    },

    /**
     * Get the formatted delivery of a license
     * @param {License} license - license to format
     */
    getFormattedDeliveryOfLicense (license) {
      return this.getFormattedLicenseDelivery(license.properties.delivery)
    },

    /**
     * Get image of a license
     * @param {License} license - license to format
     */
    getLicenseImageOfLicense: function (license) {
      return this.getLicenseImageForClassAndType(license.properties.class, license.properties.type)
    },

    /**
     * Get the formatted license name by appendign version, type, class and delivery type
     * E.g.: 'METACOM 8 Vollversion, Serverlizenz - Download'
     * @param {License} license - license to format
     */
    getFormattedNameOfLicense: function (license) {
      return `METACOM ${license.version} ${this.getFormattedTypeOfLicense(license)}, ${this.getFormattedClassOfLicense(license)} - ${this.getFormattedDeliveryOfLicense(license)}`
    },

    /**
     * Get the short formatted license by appending version and class
     * E.g.: 'METACOM 8 Serverlizenz'
     * @param {Licnese} license - license to format
     */
    getShortFormattedNameOfLicense: function (license) {
      return `${this.getLicenseVersionName(license)} ${this.getFormattedClassOfLicense(license)}`
    },

    getLicenseVersionName: function (license) {
      switch (license.version) {
        case '9': return 'METACOM 9 Desktop'
        default: return `METACOM ${license.version}`
      }
    },

    getMetacomVersionName: function (version) {
      switch (version) {
        case '9': return 'METACOM 9 Desktop'
        default: return `METACOM ${version}`
      }
    },

    /**
     * Get the start code as preetty string
     * E.g.: '000 000'
     * @param {String} startCode - start code to format
     */
    getFormattedLicenseStartCode: function (startCode) {
      return `${startCode.substr(0, 3)} ${startCode.substr(3, 3)}`
    },

    /**
     * Get the formatted start code of a license
     * @param {License} license - license to format
     */
    getFormattedStartCodeOfLicense: function (license) {
      return this.getFormattedLicenseStartCode(license.startCode)
    },

    /**
     * Get the formatted authentication code of a license
     * Either as start code in pairs of 3 digits ('000 000') or as unformatted authentication code
     * @param {License} license - license to format
     */
    getFormattedAuthenticationCodeOfLicense: function (license) {
      return license.authenticationCode === license.startCode ? this.getFormattedLicenseStartCode(license.startCode) : license.authenticationCode
    },

    getDVDCount: function (license) {
      if (license.version === '9') {
        return license.properties.dvdCount
      }

      if (license.properties.delivery === 'download') { return 0 }
      if (license.properties.class === 'single') { return 1 }
      return Math.min(license.properties.size, 5)
    },

    getShortPropertiesText: function (license) {
      return `${this.getFormattedTypeOfLicense(license)}, ${this.getFormattedDeliveryOfLicense(license)}, Größe: ${license.properties.size}, DVDs: ${this.getDVDCount(license)}`
    },

    getShortPropertiesTextForMultipleLicenses: function (license) {
      return `${this.getFormattedTypeOfLicense(license)}, ${this.getFormattedDeliveryOfLicense(license)}, Anzahl: ${license.properties.size}, DVDs: ${this.getDVDCount(license)}`
    },

    /**
     * Get license key in pretty format (pairs of 4 characters)
     * E.g. 'XXXX-XXXX-XXXX'
     * @param {String} key - 12 character license key
     */
    getFormattedLicenseKey: function (key) {
      return `${key.substr(0, 4)}-${key.substr(4, 4)}-${key.substr(8, 12)}`
    },

    /**
     * Get the formatted license key of a license
     * @param {License} license - license to format
     */
    getFormattedKeyOfLicense: function (license) {
      return this.getFormattedLicenseKey(license.key)
    },

    /**
     * Get formatted license identifier string
     * @param {License} license - license to format
     */
    getFormattedIdentifierOfLicense: function (license) {
      const classIdentifiers = { single: 'EL', multiple: 'ML', server: 'SL', personal: 'PER', pro: 'PRO' }
      const typeIdentifiers = { full: 'VV', update: 'UP' }
      const deliveryIdentifiers = { download: 'DL', dvd: 'DVD' }

      return `M${license.version} ${classIdentifiers[license.properties.class]}(${license.properties.size}) ${typeIdentifiers[license.properties.type]} - ${deliveryIdentifiers[license.properties.delivery]}`
    },

    /**
     * Get the entry type as pretty string
     * @param {String} entryType - entryType value
     */
    getFormattedLicenseEntryType: function (entryType) {
      const entryTypes = { manual: 'Benutzer', order: 'Bestellung' }
      return entryTypes[entryType]
    },

    /**
     * Get the formatted entry type of a license
     * @param {*} license - license to format
     */
    getFormattedEntryTypeOfLicense: function (license) {
      return this.getFormattedLicenseEntryType(license.typeOfEntry)
    },

    /**
     * Get the license direct login URL
     * @param {License} license
     */
    getDirectLoginURLOfLicense: function (license) {
      return this.getDirectLoginURLWithComponents(license.key, license.authenticationCode)
    },

    /**
     * Get direct login URL from license key and authentication code
     * @param {String} key - license key
     * @param {String} authenticationCode - authentication code
     */
    getDirectLoginURLWithComponents: function (key, authenticationCode) {
      return `${process.env.VUE_APP_URL}/login?key=${encodeURIComponent(key)}&authenticationCode=${encodeURIComponent(authenticationCode)}`
    },

    /**
     * Check wether a license should show the authentication code warning
     * @param {License} license - license to check
     */
    hasLicenseAuthenticationCodeWarning: function (license) {
      return license !== undefined &&
        license.status === 'bound' &&
        license.holder !== undefined &&
        license.holder.name &&
        license.authenticationCode !== license.holder.name
    },

    getFormattedHolderType: function (license) {
      switch (license?.holder?.holderType) {
        case 'organization': return 'Organisation'
        case 'person': return 'Natürliche Person'
        default: return '-'
      }
    },

    //
    // ─── METACOM 9 Pricing ───────────────────────────────────────
    //

    getVolumeDiscountForTotalQuantity (quantity) {
      return Math.round(100 * 100 * (1 - this.getPriceFactorForQuantity(quantity))) / 100
    },

    getBasePriceOfShopProduct (shopProduct) {
      if (shopProduct.productType === 'dvd') {
        return 15
      } else {
        // Personal
        if (shopProduct.type === 'update') {
          return shopProduct.class === 'personal' ? 30 : 33
        } else {
          return shopProduct.class === 'personal' ? 80 : 90
        }
      }
    },

    /**
     * Get the name of a license assistant shop product
     * @param {Object} shopProduct Shop product created by assistant
     */
    getNameOfShopProduct (shopProduct) {
      if (shopProduct.productType === 'dvd') {
        return 'METACOM 9 Desktop - Installationsdatenträger (DVD) zur Lizenz inkl. Versand'
      } else {
        return `METACOM 9 Desktop - ${this.getFormattedLicenseClass(shopProduct.class)} ${this.getFormattedLicenseType(shopProduct.type)} (Download)`
      }
    },

    getPriceFactorForQuantity (quantity) {
      const factors = [
        [1, 1],
        [2, 0.87],
        [3, 0.82],
        [4, 0.77],
        [5, 0.72],
        [6, 0.69],
        [7, 0.66],
        [8, 0.63],
        [9, 0.6],
        [10, 0.57],
        [11, 0.55],
        [12, 0.53],
        [13, 0.51],
        [14, 0.49],
        [15, 0.47],
        [16, 0.46],
        [17, 0.45],
        [18, 0.44],
        [19, 0.43],
        [20, 0.42],
        [21, 0.41],
        [22, 0.4],
        [23, 0.39],
        [24, 0.38],
        [25, 0.37],
        [26, 0.365],
        [27, 0.360],
        [28, 0.355],
        [29, 0.350],
        [30, 0.345],
        [31, 0.340],
        [32, 0.335],
        [33, 0.330],
        [34, 0.325],
        [35, 0.320],
        [36, 0.315],
        [37, 0.310],
        [38, 0.305],
        [39, 0.300],
        [40, 0.295],
        [41, 0.290],
        [42, 0.285],
        [43, 0.280],
        [44, 0.275],
        [45, 0.27],
        [46, 0.27],
        [47, 0.27],
        [48, 0.27],
        [49, 0.27],
        [50, 0.27],
        [60, 0.25],
        [70, 0.23],
        [80, 0.21],
        [90, 0.19],
        [100, 0.17],
        [110, 0.169],
        [120, 0.168],
        [130, 0.167],
        [140, 0.166],
        [150, 0.165],
        [160, 0.164],
        [170, 0.163],
        [180, 0.162],
        [190, 0.161],
        [200, 0.160],
        [210, 0.158],
        [220, 0.156],
        [230, 0.154],
        [240, 0.152],
        [250, 0.150]
      ]
      let factor = 1
      let e
      for (e of factors) {
        const [limit, f] = e
        if (limit <= quantity) {
          factor = f
        } else {
          break
        }
      }

      return factor
    },

    //
    // ─── PERMISSIONS ─────────────────────────────────────────────────
    //

    /**
     * Check if a license has permission to show the server user hints
     * @param {License} license - license to check
     */
    canShowMetasearchServerDocumentation: function (license) {
      return (license.properties && license.properties.class === 'server') ||
      (license.specialPermissions && license.specialPermissions.indexOf('metasearch-server-info') !== -1)
    },

    /**
     * Check if a license has permissions to download the metasearch multistep installer
     * @param {License} license - license to check
     */
    canDownloadMetasearchMultistepInstaller: function (license) {
      if ((license.version !== '8')) {
        return false
      }
      return (license.properties && ['server'].includes(license.properties.class)) ||
      (license.specialPermissions && license.specialPermissions.indexOf('metasearch-multistep-installer') !== -1)
    },

    /**
     * Check if a license has permissions to download the metasearch oneclick installer
     * @param {License} license - license to check
     */
    canDownloadMetasearchOneClickInstaller: function (license) {
      return (license.version === '9') ||
      (license.properties && license.properties.class === 'server') ||
      (license.specialPermissions && license.specialPermissions.indexOf('metasearch-oneclick-installer') !== -1)
    },

    /**
     * Check if a license has permission to download metasearch
     * @param {License} license - license to check
     */
    canDownloadMetacom: function (license) {
      return license.status === 'bound' && (
        (license.properties && license.properties.delivery === 'download') ||
        (license.specialPermissions && license.specialPermissions.indexOf('download') !== -1)
      )
    },

    canCopyDownloadLink: function (license) {
      return license.version === '9' && license.properties.class === 'pro' && license.properties.size > 1
    }

  }
}
